<template>
  <div class="pop_contact">
    <div class="form_bg" @click="action_Close"></div>
    <div class="contact_form">
      <!-- done -->
      <div v-if="isDone" id="consultation-complete" class="complete_bg">
        <div class="complete">
          <div><img src="@/assets/img/contact/check.png" alt="완료" /></div>
          <div>상담 신청이 완료 되었습니다.</div>
        </div>
      </div>
      <!-- indicator -->
      <div v-if="isLoading" class="loading_bg">
        <span class="loader"></span>
      </div>

      <div class="form_title">
        <div class="main_title">상담 문의</div>
        <div class="sub_title">아래 양식에 맞춰 문의를 남겨 주시면<br />담당 매니저가 확인 후 빠르게 연락 드리겠습니다.</div>
      </div>
      <ul>
        <li class="list list1">
          <p>성함을 입력해 주세요.</p>
          <input type="text" class="text" placeholder="예) 김닥터" v-model="sender_Name" />
        </li>
        <li class="list list2">
          <p>연락 가능한 연락처를 남겨주세요.</p>
          <input type="text" class="text" placeholder="예) 000-0000-0000" v-model="sender_Phone" />
        </li>
        <li class="list list3">
          <p>병·의원명(개원 예정인 경우 '개원 예정'을 체크해 주세요)</p>
          <input type="text" class="text" placeholder="예) 애프터닥의원" v-model="sender_HospitalName" />
          <input type="checkbox" id="open" v-model="sender_HospitalWillOpen" /><label for="open">개원 예정</label>
        </li>
        <li class="list list4">
          <p>지역</p>
          <div class="location">
            <input type="text" class="text" placeholder="예) 서울" v-model="sender_Location_City" /><span>시</span>
            <input type="text" class="text" placeholder="예) 강남" v-model="sender_Location_District" /><span>구</span>
          </div>
        </li>
        <li class="list list5">
          <p>관심있으신 부분을 체크해 주세요.</p>
          <div class="box">
            <div class="left">
              <div v-for="(data, index) in checkList_Left" :key="index">
                <input type="checkbox" :id="data.value" v-model="data.isCheck" />
                <label :for="data.value">{{ data.value }}</label>
              </div>
            </div>
            <div class="right">
              <div v-for="(data, index) in checkList_Right" :key="index">
                <input type="checkbox" :id="data.value" v-model="data.isCheck" />
                <label :for="data.value">{{ data.value }}</label>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="submit_btn">
        <input type="submit" @click="action_SendEmail" value="상담 신청" />
      </div>

      <div class="postscript">*제출하신 상담 신청서는 절대 외부로 공유되지 않으며, 오직 도입 상담을 위한 준비과정에서만 사용됩니다.</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
const axios = require("axios");

const URL_Email_Send = process.env.VUE_APP_EMAIL;
const Message_Error = "필수항목(성함, 병원명, 연락처,지역)을 입력해 주세요.";
const Email_Subject = "[홈페이지 문의]";
const Email_Subject_AL = "[전능 홈페이지 문의]"

const URL_SMS_SEND = process.env.VUE_APP_SMS
const SMS_Title = process.env.VUE_APP_SMS_MESSAGE_TITLE
const SMS_Message = process.env.VUE_APP_SMS_MESSAGE
const SMS_Sender = process.env.VUE_APP_SMS_SENDER
const SMS_Replacer = process.env.VUE_APP_SMS_MESSAGE_REPLACER

export default {
  props: {
    fromALEvent: {
      type: Boolean,
      default: false,
      identifier: String,
    }
  },
  data() {
    return {
      isLoading: false,
      isDone: false,

      isContactPopupOpen: false,

      sender_Name: "",
      sender_Phone: "",
      sender_HospitalName: "",
      sender_HospitalWillOpen: false,
      sender_Location_City: "",
      sender_Location_District: "",

      checkList_Left: [
        {
          isCheck: false,
          value: "병·의원의 매출 성장",
        },
        {
          isCheck: false,
          value: "환자의 만족도에 대한 관심",
        },
        {
          isCheck: false,
          value: "병·의원 확장",
        },
      ],
      checkList_Right: [
        {
          isCheck: false,
          value: "타 의원과의 차별화 포인트",
        },
        {
          isCheck: false,
          value: "새로운 서비스 도입에 대한 관심",
        },
        {
          isCheck: false,
          value: "기타",
        },
      ],
    };
  },
  methods: {
    closeContactPopup() {
      this.isContactPopupOpen = false;
    },
    action_Close() {
      this.$emit("action_Close", false);
    },
    async action_SendEmail() {
      let isPass = true;
      if (this.sender_Name == undefined || this.sender_Name == "") {
        isPass = false;
      }
      if (this.sender_Phone == undefined || this.sender_Phone == "") {
        isPass = false;
      }
      if (
        this.sender_HospitalName == undefined ||
        this.sender_HospitalName == ""
      ) {
        if (this.sender_HospitalWillOpen == false) {
          isPass = false;
        }
      }
      if (
        this.sender_Location_City == undefined ||
        this.sender_Location_District == ""
      ) {
        isPass = false;
      }
      if (isPass == false) {
        alert(Message_Error);
        return;
      }

      window.fbq('track', 'CompleteRegistration')
      this.isLoading = true;
      let body = "";
      body += "이름 : \n\t" + this.sender_Name + "\n\n";
      body += "연락가능한 전화번호 : \n\t" + this.sender_Phone + "\n\n";
      body += "병·의원명 : \n\t" + this.sender_HospitalName;
      if (this.sender_HospitalWillOpen == true) {
        body += "개원예정";
      }
      body += "\n\n";
      body +=
        "지역 : \n\t" +
        this.sender_Location_City +
        "시" +
        this.sender_Location_District +
        "구" +
        "\n\n";
      body += "관심있는 항목 : \n";

      let leftSideValue = "";
      let rightSideValue = "";
      for (let i = 0; i < this.checkList_Left.length; i++) {
        if (this.checkList_Left[i].isCheck == true) {
          leftSideValue += "\t" + this.checkList_Left[i].value + "\n";
        }
      }
      for (let i = 0; i < this.checkList_Right.length; i++) {
        if (this.checkList_Right[i].isCheck == true) {
          rightSideValue += "\t" + this.checkList_Right[i].value + "\n";
        }
      }
      if (
        (leftSideValue == undefined || leftSideValue == "") &&
        (rightSideValue == undefined || rightSideValue == "")
      ) {
        body += "\t-\n";
      } else {
        body += leftSideValue + rightSideValue;
      }

      // add cookie
      body += '\n\n=============================='
      body += '\nAddtional Information from Browser -'
      body += '\n\ncookie : \n' + document.cookie
      body += '\n\nreferrer : \n' + document.referrer
      body += '\n\nuserAgent : \n' + navigator.userAgent

      let title = this.fromALEvent == false ? Email_Subject : Email_Subject_AL
      if (this.$attrs.identifier != undefined && this.$attrs.identifier != '') {
        title = '[' + this.$attrs.identifier + ']' + title
      }
      let model = {
        subject: title,
        text: body,
      };

      let result = await axios({
        url: URL_Email_Send,
        method: "POST",
        data: model,
      });

      try {
        // send sms
        let smsModel = {
          title: SMS_Title,
          message: SMS_Message.replace(SMS_Replacer, this.sender_Name),
          sender: SMS_Sender,
          receiver: this.sender_Phone
        }
        result = await axios({
          url: URL_SMS_SEND,
          method: "POST",
          data: smsModel
        })
      } catch(err) {
        console.log(err)
      }

      this.$nextTick(() => {
        this.isLoading = false;
        this.isDone = true;

        let THIS = this;

        setTimeout(function () {
          THIS.isDone = false;
          THIS.action_Close();
        }, 2000);
      });
    },
  },
};
</script>

<style scoped>
.main_title {
  font-size: 36px;
  line-height: 150%;
  margin-bottom: 16px;
}
.sub_title {
  color: #444;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  margin: 0 auto !important;
}

/* loading */
.loading_bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--color_primary_1);
  animation: spinner 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

/* complete */
.complete_bg {
  width: 100%;
  height: 100vh;
  background-color: var(--color_fe);
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: up 0.2s 1 ease-out;
}
@keyframes up {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
.complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 24px;
  font-weight: var(--bold);
}
.complete img {
  max-width: 80px;
  width: 100%;
}
</style>
