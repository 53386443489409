import { createWebHistory, createRouter } from "vue-router"

import Main from "../views/Main/MainView.vue"
import Event_AL from "../views/Event/AL/Event_AL.vue"
import Event_Message from '../views/Event/Message/Event_Message.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Main,
      name: "MainView",
    },
    {
      path: "/contact",
      component: Main,
      name: "openPopup",
    },
    {
      path: "/contact/:key?",
      component: Main,
      name: "openPopup",
    },
    {
      path: "/event/al",
      component: Event_AL,
      name: "Event_AL"
    },
    {
      path: '/event/m',
      component: Event_Message,
      name: 'Event_Message'
    }
  ],
})

export { router }
