<template>
  <div class="pop_faq">
    <div class="faq_bg" @click="action_Close"></div>
    <div class="faq">
      <div class="title">자주 묻는 질문</div>

      <ul class="faq_list">
        <li class="list" v-for="(item, index) in faqItems" :key="index">
          <div
            class="row"
            @click="toggleClicked(index)"
            :class="{ toggle_btn: item.wrapOpen }"
          >
            <div class="question" v-html="formatText(item.question)"></div>
            <div class="toggle">
              <img src="@/assets/img/faq/toggle.png" alt="펼치기 버튼" />
            </div>
          </div>
          <ul class="wrap" v-if="item.wrapOpen">
            <li>
              <div style="font-weight: 500">
                <span v-html="formatText(item.content)"></span>
              </div>
              <div class="content">
                <span v-html="formatText(item.answer)"></span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqItems: [
        {
          wrapOpen: false,
          question: "[도입 문의] 서비스/가격 등 궁금한 점이 있습니다.",
          content:
            "애프터닥은 도입을 원하시는 병·의원의 규모와 진료 분야에 따라 커스터마이징 되어 제공되는 솔루션입니다. 도입을 원하신다면 방문하여 상담을 통해 서비스 방향을 결정하고 가격이 책정됩니다. 아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.",
          answer:
            "1. 애프터닥 홈페이지 상단에 있는 [도입 문의]를 통해 문의해 주세요. <br>2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다. <br>3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.",
        },
        {
          wrapOpen: false,
          question: "[도입 문의] 애프터닥을 이용하고 싶습니다.",
          content:
            "애프터닥에 관심 가져 주셔서 감사합니다. <br> 애프터닥의 도입은 홈페이지 내 [도입 문의]를 통해 신청하실 수 있습니다. <br>아래 단계에 따라 문의 주신다면 확인 후 빠르게 답변드리도록 하겠습니다.",
          answer:
            "1. 애프터닥 홈페이지 상단에 있는 [도입 문의]를 눌러 문의해 주세요. <br>2. 홈페이지 내 온라인 문의는 연락처를 정확하게 입력해 주셔야 회신드릴 수 있습니다. <br>3. 문의사항은 담당자 확인 후 영업일 기준 0~1일 내 답변을 드립니다.",
        },
        {
          wrapOpen: false,
          question: "[서비스 문의] 애프터닥 상담실장은 누구인가요?",
          content:
            "애프터닥 상담실장은 임상에서의 풍부한 환자 관리 경험을 보유하고, 전문적인 서비스 교육을 수료한 상담인력입니다. 솔루션 도입 이후 병·의원에 전담 배정되어 온라인 채널을 통해 내원한 환자분들의 사후 관리를 담당합니다.",
          answer:
            "더 궁금하신 사항이 있으시다면 홈페이지 상단의 [도입 문의]를 통해 남겨주세요. <br>확인 후 빠르게 담당자가 답변드리도록 하겠습니다.",
        },
        {
          wrapOpen: false,
          question:
            "[서비스 문의] 애프터닥 소프트웨어는 어떻게 구성되어 있나요?",
          content:
            "애프터닥에서 제공하는 소프트웨어는 병·의원에 개별적으로 제공되는 플랫폼을 관리, 운영할 수 있는 소프트웨어입니다. 제공받으신 플랫폼은 해당 병·의원에 내원한 환자들만이 가입이 가능하며, 환자분들은 스마트폰 Application을 통해 그리고 병·의원에서는 관리용 PC프로그램을 통해 사용하실 수 있습니다.",
          answer:
            "더 궁금하신 사항이 있으시다면 홈페이지 상단의 [도입 문의]를 통해 남겨주세요. <br>확인 후 빠르게 담당자가답변 드리도록 하겠습니다.",
        },
        {
          wrapOpen: false,
          question:
            "[서비스 문의] 기존 EMR/CRM 프로그램을 사용중이었는데, 무엇이 다른가요?",
          content:
            "병·의원에서 기존 사용하시던 EMR/CRM 프로그램과 애프터닥에서 제공하는 소프트웨어는 별도로 설치 운영됩니다. 애프터닥의 소프트웨어는 병·의원의 환자 자산을 확보하기 위한 플랫폼을 운영하기 위한 소프트웨어로써, 기록을 위한 EMR/CRM과는 다른 목적을 가지고 있습니다.",
          answer:
            "더 궁금하신 사항이 있으시다면 홈페이지 상단의 [도입 문의]를 통해 남겨주세요. <br>확인 후 빠르게 담당자가 답변드리도록 하겠습니다.",
        },
      ],
    };
  },
  computed: {},

  methods: {
    formatText(text) {
      const targets = ["[도입 문의]", "[서비스 문의]"];
      let formattedText = text;

      targets.forEach((target) => {
        const index = formattedText.indexOf(target);
        if (index !== -1) {
          formattedText =
            formattedText.substring(0, index) +
            '<span class="highlight">' +
            target +
            "</span>" +
            formattedText.substring(index + target.length);
        }
      });

      return formattedText;
    },

    closeContactPopup() {
      this.isfaqPopupOpen = false;
    },
    action_Close() {
      this.$emit("action_Close", false);
    },

    toggleClicked(index) {
      // 클릭한 토글의 wrapOpen 상태를 토글
      this.faqItems[index].wrapOpen = !this.faqItems[index].wrapOpen;

      // 다른 토글의 wrapOpen 상태를 초기화
      for (let i = 0; i < this.faqItems.length; i++) {
        if (i !== index) {
          this.faqItems[i].wrapOpen = false;
        }
      }
    },
  },
};
</script>
<style>
.highlight {
  color: #007aff;
}
</style>
